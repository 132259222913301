import { Box, Grid, Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import * as React from "react";
import { i18n } from "../../translate/i18n";
import { useEffect, useState } from "react";
import FilterList from "../../components/filterList/FilterList";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import { useParams } from "react-router-dom";
import pdf from "../../assets/file_pdf.svg";
import exl from "../../assets/file_Exel.svg";
import Loading from "../../components/loading/loading";


export default function FinalGradeList() {
  const [incomingData, setIncomingData] = useState([]);
  const [finalGradeData, setFinalGradeData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const {subjectId,subjectName} = useParams();
  const cellStyles = {
    border: "1px solid #E9ECF5",
    padding: "0px",
    maxWidth: "42px", // Ajuste conforme necessário
    width: "42px", // Ajuste conforme necessário
  };
  const tableCellStyles = {
    display: "flex",
    justifyContent: "center",
    width: "48px",
    padding: "2px",
    borderRight: "1px solid #E9ECF5",
    height: "28px",
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [evaluationSubjectResponse, finalGradeResponse] = await Promise.all([
          axios.get(`${apiUrl}/evaluation/subject/${subjectId}`),
          axios.get(`${apiUrl}/evaluation/final-grade-of-one-subject/${subjectId}`)
        ]);
        setIncomingData(evaluationSubjectResponse.data);
        setFinalGradeData(finalGradeResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl, subjectId]);
  const handleDownloadExcel = async () => {
    try {
      const response = await axios.get(`${apiUrl}/excel-generator/${subjectId}`, {
        responseType: "blob" // Indica que a resposta é um arquivo binário
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "final_grades.xlsx"); // Nome do arquivo de download
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <Grid container>
      {loading && <Loading />}
      <Grid id="FinalGrade" item xs={12}>
        {loading ? null : (
          <TableContainer sx={{ overflow: "auto" }}>
            <Table component={Paper} id="table-to-xls" sx={{ width: "1200px" }} size="small" aria-label="a dense table"
            >
              <TableHead>
                <TableRow height="81px">
                  <TableCell align="left" colSpan={15} sx={{ border: "1px solid #E9ECF5" }}>
                    <Typography variant="h3" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                      {subjectName}
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Box sx={{ width: "200px" }}>
                          <AutoSearch
                            placeholder={i18n.t("others.search")} 
                          />                                                   
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "120px",
                            height: "40px",
                            border: "1px solid #E9ECF5",
                          }}
                        >
                          <FilterList
                            cardStyle={{
                              position: "absolute",
                              indez: "1",
                              top: "145px",
                              right: "306px",
                            }}
                          />
                          {i18n.t("others.filter")}
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "62px",
                            height: "36px",
                            border: "1px solid #E9ECF5",
                          }}
                        ><button onClick={handleDownloadExcel} style={{ textDecoration: "none", cursor: "pointer", border: "none", background: "none" }}>
                            <img
                              src={exl}
                              alt="excel"
                              style={{ width: "19px", height: "20px" }}
                            />
                          </button>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "62px",
                            height: "36px",
                            border: "1px solid #E9ECF5",
                          }}
                        >
                          <a
                            
                            download="FinalGrade.pdf"
                            style={{
                              textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                        
                          >
                            <img
                              src={pdf}
                              alt="pdf"
                              style={{ width: "19px", height: "20px" }}
                            />
                          </a>
                        </Box>
                      </Box>
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow
                  id="table-to-xls"
                  height="65px"
                  style={{
                    gap: "0px",
                    backgroundColor: "#F8FAFD",
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid #E9ECF5",
                      width: "134px",
                      marginLeft: "1px",
                    }}
                  >
                    {" "}
                    {i18n.t("others.mechanographicNº")}{" "}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid #E9ECF5",
                      marginLeft: "1px",
                      width: "250px",
                    }}
                  >
                    {" "}
                    {i18n.t("others.name")}{" "}
                  </TableCell>

                  <TableCell
                    align="center"
                    colSpan={6}
                    sx={{
                      padding: "0px",
                      border: "none",
                    }}
                  >
                    <TableCell
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        borderRight: "1px solid #E9ECF5",
                        borderLeft: "1px solid #E9ECF5",
                        marginLeft: "2px",
                        height: "32px",
                        padding: "0px",
                        
                      }}
                    >
                      {" "}
                      {i18n.t("others.continuousEvaluation")}{" "}
                    </TableCell>{" "}
                    <TableCell
                    
                      sx={{
                        borderBottom: "none",
                        padding: "0px",
                       
                        height: "33px",
                        display: "flex",
                        marginLeft: "2px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TableCell
                       sx={tableCellStyles}
                      >
                        {" "}
                        {i18n.t("BI")}
                      </TableCell>{" "}
                      <TableCell
                        sx={tableCellStyles}
                      >
                        {" "}
                        {i18n.t("BII")}
                      </TableCell>
                      <TableCell
                       sx={tableCellStyles}
                      >
                        {" "}
                        {i18n.t("TI")}
                      </TableCell>
                      <TableCell
                        sx={tableCellStyles}
                      >
                        {" "}
                        {i18n.t("TII")}
                      </TableCell>
                      <TableCell
                       sx={tableCellStyles}
                      >
                        {" "}
                        {i18n.t("PI")}
                      </TableCell>
                      <TableCell
                        sx={tableCellStyles}
                      >
                        {" "}
                        {i18n.t("PII")}
                      </TableCell>
                    </TableCell>
                  </TableCell>

                  <TableCell
                   
                    colSpan={4}
                    sx={{
                     
                      padding: "0px",
                      borderBottom: "none",
                      height: "60px",
                    }}
                  >
                    <TableCell
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        borderRight: "1px solid #E9ECF5",
                        borderLeft: "1px solid #E9ECF5",
                        marginRight: "2px",
                        marginLeft: "2px",
                        height: "32px",
                        padding: "0px",
                        width: "219px",
                      }}
                    >
                      {i18n.t("")}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        // height: "32px",
                        display: "flex",
                        padding: "0px",
                        marginLeft: "2px",
                        // width: "230px",
                      }}
                    >
                      <TableCell
                        sx={{
                          borderRight: "1px solid #E9ECF5",
                          borderLeft: "1px solid #E9ECF5",
                          display: "flex",
                          justifyContent: "center",
                          // marginLeft: "2px",
                          padding: "2px",
                          width: "50px",
                          height: "28px",
                        }}
                      >
                        {" "}
                        {i18n.t("MAC")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #E9ECF5",
                          display: "flex",
                          justifyContent: "center",
                          padding: "2px",
                          width: "50px",
                          height: "28px",
                        }}
                      >
                        {" "}
                        {i18n.t("Exa")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #E9ECF5",
                          display: "flex",
                          justifyContent: "center",
                          padding: "2px",
                          width: "52px",
                          height: "28px",
                        }}
                      >
                        {" "}
                        {i18n.t("NF")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #E9ECF5",
                          display: "flex",
                          justifyContent: "center",
                          padding: "2px",
                          width: "50px",
                          height: "28px",
                        }}
                      >
                        {" "}
                        {i18n.t("REC")}
                      </TableCell>
                    </TableCell>
                  </TableCell>

                  <TableCell
                    colSpan={2}
                    align="left"
                    sx={{
                      border: "1px solid #E9ECF5",
                      width: "355px",
                      paddingLeft: "28px",
                    }}
                  >
                    {" "}
                    {i18n.t("others.finalResult")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
              {finalGradeData.map(student => {
    const { studentId,studentName,nMec, finalGrade, result, AverageGrade } = student;
    const evaluations = incomingData.find(data => data.studentId === studentId)?.evaluations ?? [];

    // Inicializa um objeto para armazenar todas as notas de avaliação
    const evaluationValues = {
      'EXA': "-",
      'P II': "-",
      'B I': "-",
      'B II': "-",
      'T I': "-",
      'T II': "-",
      'P I': "-",
      'REC': "-"
    };

    // Atualiza as notas de avaliação com valores reais, se disponíveis
    evaluations?.forEach(evaluation => {
      const { evaluationType, value } = evaluation;
      if (evaluationType in evaluationValues) {
        evaluationValues[evaluationType] = value;
      }
    });
                  return (
                    <TableRow key={nMec}>
                      <TableCell align="left" sx={{
                        border: "1px solid #E9ECF5",
                        height: "40px",
                        margin: "1px",
                      }}
                      >{nMec}</TableCell>
                      <TableCell align="left" sx={{ margin: "1px", border: "1px solid #E9ECF5" }}>{studentName}</TableCell>
                      <TableCell align="center" sx={{
                        border: "1px solid #E9ECF5",
                        padding: "0px",
                        width: "44px",
                        maxWidth: "44px",
                      }}
                      >{evaluationValues['B I']}</TableCell>
                      <TableCell align="center" sx={{
                        ...cellStyles
                      }}
                      >{evaluationValues['B II']}</TableCell>
                      <TableCell align="center" sx={{
                        ...cellStyles
                      }}
                      >{evaluationValues['T I']}</TableCell>
                      <TableCell align="center" sx={{...cellStyles }}   >{evaluationValues['T II']}</TableCell>
                      <TableCell align="center" sx={{...cellStyles }} >{evaluationValues['P I']}</TableCell> 
                      <TableCell align="center" sx={{...cellStyles, truncate: true, }} >{evaluationValues['P II']}</TableCell>
                      <TableCell align="center" sx={{ ...cellStyles}} >{AverageGrade?? "-"}</TableCell>
                      <TableCell align="center" sx={{...cellStyles, margin: "5px", }} >{evaluationValues['EXA']}</TableCell>
                      <TableCell align="center" sx={{...cellStyles}} >{finalGrade}</TableCell>
                      <TableCell align="center" sx={{ ...cellStyles  }}>{evaluationValues['REC']}</TableCell>
                      <TableCell align="center" sx={{ border: "1px solid #E9ECF5", width: "60px" }}>{finalGrade}</TableCell>
                      <TableCell align="center" sx={{ color: result === "Aprovado" ? "green" : "red", border: "1px solid #E9ECF5", width: "106px" }}>{result}</TableCell>
                    </TableRow>
                  );
                })}
                </TableBody>
                </Table>
          </TableContainer>
        )}
             <Grid item xs={12} sx={{ marginTop: "30px", display: "flex" }}>
          <Grid sx={{ marginLeft: "168px" }}>
            <Typography>{i18n.t("others.NfFinalGrade")}</Typography>
            {i18n.t("NF = (MAC x 0,4)+(EXAME x 0,6)")}
          </Grid>

          <Grid sx={{ marginLeft: "62px" }}>
            <Typography>
              {i18n.t("others.MAcContinuousAssessmentAverage")}
            </Typography>
          </Grid>

          <Grid sx={{ marginLeft: "235px" }}>
            <Typography>{i18n.t("others.EXRecAppealExam")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {!loading && (
        <Grid item xs={12}>

        </Grid>
      )}
    </Grid>
  );
}
            
                    
                    
                    
                       
                    
                      
                     
           


    