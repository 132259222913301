import { useCallback, useEffect, useState } from "react";
import { Button, Typography, createTheme, ThemeProvider, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Selected from "../../components/form-components/select";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import HandleUrl from "../../utils/HandleUrl";
import { Form, Formik } from "formik";
import CardSchedules from "../schedules/components/CardSchedules";
import { i18n } from "../../translate/i18n";
import Loading from "../../components/loading/loading";

const theme = createTheme({
   palette: {
      warning: {
         main: "#FFC107",
      },
   },
});

const ScheduleTable = () => {
   const { keycloak } = useKeycloak();
   let apiUrl =process.env.REACT_APP_API_URL;
   const [days, setDays] = useState([]);
   const [times, setTimes] = useState([]);
   const [isDisabled, setIsDisabled] = useState(HandleUrl());
   const [classes, setClasses] = useState([]);
   const [classrooms, setClassrooms] = useState([]);
   const [groups, setGroups] = useState([]);
   const [period, setPeriod] = useState([]);
   const [strand, setStrand] = useState([]);

   const [loading, setLoading] = useState(false);

   const [selectedStrand, setSelectedStrand] = useState({});
   const [selectedPeriod, setSelectedPeriod] = useState({});
   const [listDiscipline, setListDiscipline] = useState({});

   const [scheduleData, setScheduleData] = useState([]);
   const [scheduleMap, setScheduleMap] = useState({});
   const [handleSearchExecuted, setHandleSearchExecuted] = useState(false);

   const validateSearchCriteria = () => listDiscipline[0]?.id && selectedPeriod[0]?.name;

   // Define a função handleSearch
   const handleSearch = async () => {
      try {
         if (validateSearchCriteria()) {
            setHandleSearchExecuted(true);
         } else {
            console.error("Critérios de pesquisa inválidos. Por favor, selecione opções válidas.");
         }
      } catch (error) {
         console.error("Erro ao executar a busca:", error);
      }
   };

   useEffect(() => {
      const fetchClasses = async () => {
         try {
            const userId = keycloak.idTokenParsed.sub;
            const response = await axios.get(`${apiUrl}/employees/user-account/${userId}`, {
               headers: {
                  Authorization: "Bearer " + keycloak.token,
               },
            });
            setClasses(response.data.subjects);
         } catch (error) {
            console.error("Erro ao buscar as classes:", error);
         }
      };

      // Inicializar as classes
      fetchClasses();
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         setLoading(true);

         try {
            const userId = keycloak.idTokenParsed.sub;

            const params = {
               subjectId: listDiscipline[0]?.id,
               period: selectedPeriod[0]?.name,
            };

            const response = await axios.get(`${apiUrl}/employees/user-account/${userId}`, {
               headers: {
                  Authorization: "Bearer " + keycloak.token,
               },
            });
            const employeeData = response.data;

            let endpoint = selectedStrand[0]?.name === "Teórica" ? "scheduler" : "scheduler-p";

            const responseScheduler = await axios.get(`${apiUrl}/${endpoint}/teacher/${employeeData?.id}`, {
               headers: {
                  Authorization: "Bearer " + keycloak.token,
               },
               params: params,
            });

            const filteredSchedule = responseScheduler?.data.filter(
               (data) => data?.subject?.id === params.subjectId
               // data?.teachingTime?.period === params.period
            );

            setScheduleData(filteredSchedule);

            setClasses(employeeData.subjects);
            getDisciplineData(filteredSchedule?.[0]?.subject.id);
            getPeriodData(filteredSchedule[0]?.teachingTime?.period?.id);
         } catch (error) {
            console.error("Error fetching schedules:", error);
            throw error;
         } finally {
            setLoading(false);
         }
      };

      if (handleSearchExecuted) {
         fetchData();

         setHandleSearchExecuted(false);
      }
   }, [handleSearchExecuted]);

   useEffect(() => {
      if (scheduleData && scheduleData?.length > 0) {
         setScheduleMap(transformScheduleData(scheduleData, days, times));
      }
   }, [scheduleData, days, times]);

   const getList = useCallback(
      async (type, set) => {
         try {
            const response = await axios.get(`${apiUrl}/${type}`, {
               headers: {
                  Authorization: "Bearer " + keycloak.token,
               },
            });

            set(response.data?.data ? response.data?.data : response?.data);
            return response.data?.data ? response.data?.data : response?.data;
         } catch (error) {
            console.error(error);
         }
      },
      [apiUrl, keycloak.token]
   );

   useEffect(() => {
      getList("classrooms", setClassrooms);
      getList("period", setPeriod);
      getList("strand", setStrand);
      getList("teaching-days", setDays);
      getList("teaching-time", setTimes);
   }, [apiUrl, getList, keycloak.token]);

   const transformScheduleData = (data, days, times) => {
      const scheduleMap = {};

      days?.forEach((day) => {
         times.forEach((time) => {
            const newKey = `${day.id}#${time.id}`;
            scheduleMap[newKey] = {
               groupId: null,
               classroomId: null,
               disciplineId: null,
            };
         });
      });

      for (const dataKey in data) {
         if (Object.hasOwnProperty.call(data, dataKey)) {
            const slot = data[dataKey];
            const slotKey = `${slot.teachingDays?.id}#${slot.teachingTime?.id}`;
            if (scheduleMap[slotKey]) {
               scheduleMap[slotKey].groupId = slot.group ? slot.group.id : null;
               scheduleMap[slotKey].classroomId = slot.classroom.name || null;
               scheduleMap[slotKey].disciplineId = slot.subject ? slot.subject?.id : null;
            }
         }
      }
      return scheduleMap;
   };

   async function getDisciplineData(e) {
      if (e && e.target) {
         let disciplines = classes.filter((elemento) => elemento?.id === e.target.value);
         setListDiscipline(disciplines);
         setGroups(disciplines?.groups);
      }
   }

   async function getStrandData(e) {
      if (e?.target) {
         let stranded = strand.filter((elemento) => elemento.id === e.target.value);
         setSelectedStrand(stranded);
      }
   }

   async function getPeriodData(e) {
      if (e?.target) {
         let periods = period.filter((elemento) => elemento.id === e.target.value);
         setSelectedPeriod(periods);
      }
   }

   return (
      <div>
         <Grid container spacing={2}>
            <Grid xs={12}>
               {loading && <Loading /> }
               <Formik onSubmit={(values) => {}} enableReinitialize>
                  <Form>
                     <Grid
                        style={{
                           marginTop: "20px",
                           marginLeft: "-10px",
                           border: "1px solid #ccc",
                           borderRadius: "4px",
                           backgroundColor: "#fff",
                        }}
                        container
                     >
                        <Grid
                           xs={12}
                           align="right"
                           sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              padding: "10px",
                              borderBottom: "1px solid #ccc",
                           }}
                        >
                           <Grid xs={2}>
                              <Selected
                                 name="class"
                                 options={classes}
                                 label={i18n.t("course.class")}
                                 disabled={isDisabled}
                                 onChange={(e) => getDisciplineData(e)}
                              ></Selected>
                           </Grid>
                           <Grid xs={2}>
                              <Selected
                                 name="strand"
                                 options={strand}
                                 label={i18n.t("course.strand")}
                                 disabled={isDisabled}
                                 onChange={(e) => getStrandData(e)}
                              ></Selected>
                           </Grid>
                           <Grid xs={2}>
                              <Selected
                                 name="period"
                                 options={period}
                                 label={i18n.t("course.period")}
                                 disabled={isDisabled}
                                 onChange={(e) => getPeriodData(e)}
                              ></Selected>
                           </Grid>
                           <Grid sx={{ paddingTop: "0.8rem" }}>
                              <ThemeProvider theme={theme}>
                                 <Button
                                    variant="contained"
                                    fullWidth={true}
                                    color="warning"
                                    sx={{
                                       color: "white",
                                       minWidth: "0px",
                                       width: "10px",
                                    }}
                                    onClick={handleSearch}
                                 >
                                    <SearchIcon />
                                 </Button>
                              </ThemeProvider>
                           </Grid>
                        </Grid>
                        <Grid
                           style={{
                              marginTop: "10px",
                           }}
                           container
                        >
                           <Typography
                              align="right"
                              variant="h1"
                              sx={{
                                 display: "flex",
                                 justifyContent: "space-between",
                                 alignItems: "center",
                                 width: "100%",
                                 padding: "10px",
                              }}
                           >
                              {i18n.t("teacher.schedulesGrid")}
                           </Typography>
                        </Grid>

                        <Grid item xs={12}>
                           <div style={{ marginLeft: "10px", width: "100%" }}>
                              <CardSchedules
                                 schedule={scheduleMap}
                                 groups={groups}
                                 classrooms={classrooms}
                                 disciplines={listDiscipline}
                                 setSchedule={setScheduleMap}
                                 selectedPeriod={selectedPeriod}
                                 selectedStrand={selectedStrand}
                                 viewOnly={true}
                              />
                           </div>
                        </Grid>
                     </Grid>
                  </Form>
               </Formik>
            </Grid>
         </Grid>
      </div>
   );
};

export default ScheduleTable;
