import * as React from "react";
import { Grid, Tabs, Tab, Box, createTheme, ThemeProvider } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import HandleUrl from "../../../../utils/HandleUrl";
import FilterComponent from "../../components/filter/filterComponet";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import CardSchedules from "../../../schedules/components/CardSchedules";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { i18n } from "../../../../translate/i18n";
import { transformScheduleData } from "../../../schedules/functions";
import Loading from "../../../../components/loading/loading";

const theme = createTheme({
   palette: {
      warning: {
         main: "#FFC107",
      },
   },
});

function CustomTabPanel({ children, value, index }) {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

CustomTabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

export default function SchedulesSelect({
   selectedSubject,
   selectedSemester,
   selectedYear,
   selectedStrand,
   canFilter,
   setCanFilter,
}) {
   const apiUrl = process.env.REACT_APP_API_URL;
   const { keycloak } = useKeycloak();
   const { id } = useParams();
   const [isDisabled] = useState(HandleUrl());
   const [value, setValue] = useState(0);
   const [groups, setGroups] = useState([]);
   const [classrooms, setClassrooms] = useState([]);
   const [classes, setClasses] = useState([]);
   const [days, setDays] = useState([]);
   const [times, setTimes] = useState([]);
   const [studentSchedule, setStudentSchedule] = useState({});
   const [teacherSchedule, setTeacherSchedule] = useState({});
   const [incomingData, setIncomingData] = useState([]);

   const queryParameters = new URLSearchParams(window.location.search);
   const type = queryParameters.get("type");

   const [loading, setLoading] = useState(false);

   const [listStudentDiscipline, setListStudentDiscipline] = useState({});
   const [selectedStudentPeriod, setSelectedStudentPeriod] = useState({});
   const [period, setPeriod] = useState([]);
   const [strand, setStrand] = useState([]);

   const [listTeacherDiscipline, setListTeacherDiscipline] = useState({});
   const [selectedTeacherPeriod, setSelectedTeacherPeriod] = useState({});

   const getList = useCallback(async (type, set) => {
      try {
         const response = await axios.get(`${apiUrl}/${type}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });

         set(response.data?.data ? response.data?.data : response?.data);
         return response.data?.data ? response.data?.data : response?.data;
      } catch (error) {
         console.error(error);
      }
   });

   useEffect(() => {
      getList("classes/class-subject/by-coordinator", setClasses);
      getList("classrooms", setClassrooms);
      getList("period", setPeriod);
      getList("strand", setStrand);
      getList("teaching-days", setDays);
      getList("teaching-time", setTimes);
   }, [apiUrl]);

   const getSchedulerByCourse = useCallback(async () => {
      setLoading(true);

      if (selectedSubject.length === 0 || selectedSemester.length === 0 || selectedYear.length === 0) {
         return; // No data selected
      }
      try {
         const params = {
            subjectId: selectedSubject[0],
            semester: selectedSemester[0],
            year: selectedYear[0],
         };

         let endpoint = selectedStrand[0] === "Teorica" ? "scheduler" : "scheduler-p";

         const { data: studentSchedules } = await axios.get(`${apiUrl}/${endpoint}/get-course-schedules/${id}`, {
            headers: { Authorization: "Bearer " + keycloak.token },
            params: params,
         });

         const { data: teacherSchedules } = await axios.get(`${apiUrl}/${endpoint}/schedules-for-coordinator/${id}`, {
            headers: { Authorization: "Bearer " + keycloak.token },
            params: params,
         });

         const filteredStudentsSchedules = studentSchedules?.data.filter(
            (data) =>
               data?.schedulerDetails[0]?.subject?.name === params.subjectId &&
               data.year === params.year &&
               data.semester === params.semester
         );

         const filteredTeachersSchedules = teacherSchedules?.data.filter(
            (data) =>
               data?.schedulerDetails[0]?.subject?.name === params.subjectId &&
               data.year === params.year &&
               data.semester === params.semester
         );

         setIncomingData({
            students: filteredStudentsSchedules,
            teachers: filteredTeachersSchedules,
         });

         getStudentDisciplineData(filteredStudentsSchedules[0]?.schedulerDetails?.[0]?.subject?.id);
         period?.length !== 0 && getStudentPeriodData(filteredStudentsSchedules?.[0]?.period?.id);

         getTeacherDisciplineData(filteredTeachersSchedules[0]?.schedulerDetails?.[0]?.subject?.id);
         period?.length !== 0 && getTeacherPeriodData(filteredTeachersSchedules?.[0]?.period?.id);
      } catch (error) {
         console.error("There was an error fetching the schedules:", error);
      } finally {
         setCanFilter(false);
         setLoading(false);
      }
   }, [id, canFilter, setCanFilter]);

   useEffect(() => {
      getSchedulerByCourse();
   }, [getSchedulerByCourse]);

   const typeSchedules = type ? "schedulerPDetails" : "schedulerDetails";
   const studentScheduleMap = transformScheduleData(incomingData.students?.[0]?.[typeSchedules], days, times);
   const teacherScheduleMap = transformScheduleData(incomingData.teachers?.[0]?.[typeSchedules], days, times);

   async function getStudentDisciplineData(e) {
      setListStudentDiscipline(classes.filter((elemento) => elemento.subject?.id === parseInt(e)));
      setGroups(listStudentDiscipline[0]?.groups);
   }

   async function getStudentPeriodData(e) {
      setSelectedStudentPeriod(period.filter((elemento) => elemento.id === e));
   }

   async function getTeacherDisciplineData(e) {
      setListTeacherDiscipline(classes.filter((elemento) => elemento.subject?.id === parseInt(e)));
      setGroups(listTeacherDiscipline[0]?.groups);
   }

   async function getTeacherPeriodData(e) {
      setSelectedTeacherPeriod(period.filter((elemento) => elemento.id === e));
   }

   return (
      <Box sx={{ width: "100%" }}>
         {loading && <Loading />}{" "}
         <Box
            sx={{
               display: "flex",
               justifyContent: "space-between",
               alignItems: "end",
               border: "1px solid #E9ECF5",
            }}
         >
            <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="basic tabs example">
               <Tab label={i18n.t("button.studentSchedules")} />
               <Tab label={i18n.t("button.teacherSchedules")} />
            </Tabs>
            <Grid sx={{ paddingRight: "30px", display: "flex", gap: "30px", marginBottom: "13px" }}>
               <FilterComponent year={selectedYear} semester={selectedSemester} sx={{ margin: "auto" }} />

               <Grid sx={{ border: "1px solid #FFC107", borderRadius: "0.5rem" }}>
                  <ModeEditRoundedIcon sx={{ color: "#FFC107", margin: "0.3rem" }} />
               </Grid>
            </Grid>
         </Box>
         <CustomTabPanel value={value} index={0}>
            <CardSchedules
               schedule={studentScheduleMap}
               groups={groups}
               classrooms={classrooms}
               disciplines={listStudentDiscipline}
               setSchedule={setStudentSchedule}
               selectedPeriod={selectedStudentPeriod}
               selectedStrand={selectedStrand[0]}
               viewOnly={isDisabled}
            />
         </CustomTabPanel>
         <CustomTabPanel value={value} index={1}>
            <CardSchedules
               schedule={teacherScheduleMap}
               groups={groups}
               classrooms={classrooms}
               disciplines={listTeacherDiscipline}
               setSchedule={setTeacherSchedule}
               selectedPeriod={selectedTeacherPeriod}
               selectedStrand={selectedStrand[0]}
               viewOnly={isDisabled}
            />
         </CustomTabPanel>
      </Box>
   );
}
