import React from "react";
import "./loading.css";

const Loading = () => {
   return (
      <div className="baseDiv">
         <div id="sga1" className="base">
            <img src="/icon/sgaLabel.svg" alt="icon" width={200} height={200} className="w-auto h-auto" />
         </div>
         <div id="sga2" className="animate-pulse base">
            <img src="/icon/sgaBase.svg" alt="icon" width={200} height={200} className="w-auto h-auto CustomSpin" />
         </div>
         <div id="sga3" className="base">
            <img src="/icon/sgaLogo.svg" alt="icon" width={200} height={200} className="w-auto h-auto" />
         </div> 

         {/* <div id="sga1" className="base">
            <img src="/icon/sga-tmp.svg" alt="icon" width={200} height={200} className="w-auto h-auto" />
         </div>
         <div id="sga2" className="animate-pulse base">
            <img src="/icon/sga-tmp.svg" alt="icon" width={200} height={200} className="w-auto h-auto CustomSpin" />
         </div> */}
         
      </div>
   );
};

export default Loading;
