import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

export default function BasicCard({ title, id, NuMat, description }) {
  const navigate = useNavigate();

  const handleClick = (event) => {
    navigate(`/enrolls/${id}`);
  };

  return (
    <Card sx={{ maxWidth: 270 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={{ fontSize: '16px', color: '#003B76', fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" style={{ fontSize: '14px',color:'#212529'}}>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            textTransform: 'none', 
            border: 'none',
            fontSize:'14px',
            padding: 0, 
            minWidth: 0, 
          }}
          size="small"
          onClick={handleClick}
        >
          Ver Detalhes <ArrowForwardIcon />
        </Button>
        <div style={{ marginLeft: 'auto' }}>
          <Typography variant="body2" style={{ fontSize: '16px', color: '#003B76', fontWeight: 'bold' }}>
            +{NuMat}
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
}
