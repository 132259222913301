import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Select, MenuItem } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

export default function DisciplinesListPage() {
    const [enrolls, setEnrolls] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [semesters, setSemesters] = useState([]);
    const [selectedSemester, setSelectedSemester] = useState("");
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [studentData, setStudentData] = useState(null);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { keycloak } = useKeycloak();
    
    const handleLancarNotasClick = (subjectId, subjectName, studentId) => {
        navigate(`/subjects/students/view/${subjectId}/${encodeURIComponent(subjectName)}/${studentId}`);
    };
    
    const fetchStudentData = async () => {
        try {
            // Fetch student data
            const userId = keycloak.idTokenParsed.sub;
            const studentResponse = await axios.get(`${apiUrl}/students/user-account/${userId}`, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
    
            // Set student data
            setStudentData(studentResponse.data);
    
            // Fetch subjects data
            const studentId = studentResponse.data.id;
            const subjectsResponse = await axios.get(`${apiUrl}/students/${studentId}/subjects`, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
    
            // Set subjects data
            setEnrolls(subjectsResponse.data.data);
        } catch (error) {
            console.error(error);
        }
    };
    
    const getList = async (type, set) => {
        try {
            const response = await axios.get(`${apiUrl}/${type}`, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
            console.log("Resposta da Requisição:", response);
            set(response.data?.data ? response.data.data : response.data);
        } catch (error) {
            console.error(error);
        }
    };
    
    const fetchSubjectsData = async (userId, year, semester) => {
        try {
            // Construindo a query base
            let query = `/students/${userId}/subjects?`;
    
            // Adicionando o filtro de ano, se selecionado
            if (year !== "") {
                query += `filter.enroll.year=${year}&`;
            }
    
            // Adicionando o filtro de semestre, se selecionado
            if (semester !== "") {
                query += `filter.enroll.semester=${semester}`;
            }
    
            // Realizando a requisição com a query construída
            const subjectsResponse = await axios.get(apiUrl + query, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
    
            // Set subjects data
            setEnrolls(subjectsResponse.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getList("semester", setSemesters);
        getList("years", setYears);
        fetchStudentData();
    }, []);

    useEffect(() => {
        let subjectsList = [];
        enrolls.forEach(enroll => {
            subjectsList.push({
                id: enroll.subject.id,
                name: enroll.subject.name,
                studentId: enroll.enroll.student.id
            });
        });
        setSubjects(subjectsList);
    }, [enrolls]);

    useEffect(() => {
        // Chamada da API para obter os dados das disciplinas com base nos filtros selecionados
        const userId = studentData ? studentData.id : "";
        fetchSubjectsData(userId, selectedYear, selectedSemester);
    }, [selectedYear, selectedSemester, studentData]);
    
    return (
        <Grid sx={{ backgroundColor: "white", padding: "80px", paddingTop: "30px" }}>  
            <Grid container display={"flex"} justifyContent="space-between" alignItems="center" sx={{ marginBottom: '10px' }}>
                <Grid item>
                    <Typography variant="h4" sx={{ color: '#212529', fontWeight: 'bold',fontSize: "20px" }}>
                        Minhas Disciplinas
                    </Typography>
                </Grid>
                <Grid item alignItems="center">
                    <Select
                        label="Ano"
                        sx={{ width: '160px', height: '35px', marginRight: '10px' }}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        <MenuItem value="">Selecione um ano</MenuItem>
                        {years.map((year) => (
                            <MenuItem key={year.id} value={year.id}>{year.value} ano</MenuItem>
                        ))}
                    </Select>
                    <Select
                        label="Semestre"
                        sx={{ width: '160px', height: '35px', marginRight: '10px' }}
                        value={selectedSemester}
                        onChange={(e) => setSelectedSemester(e.target.value)}
                    >
                        <MenuItem value="">Selecione um semestre</MenuItem>
                        {semesters.map((semester) => (
                            <MenuItem key={semester.id} value={semester.id}>{semester.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            {subjects.map((subject) => (
                <Grid
                    container
                    alignItems="center"
                    key={subject}
                    sx={{
                        border: '1px solid #fff',
                        borderTop: '1px solid #fff',
                        boxShadow: '0px 8px 16px 0px #ABBED133',
                        marginBottom: '5px',
                        padding: '20px',
                        maxWidth: '100%',
                        marginLeft: "-20px",
                        position: 'relative',
                    }}
                >
                    <Typography variant="body1" sx={{ color: '#003B76', fontWeight: 'bold',fontSize: "14px" }}>{subject.name}</Typography>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "30px",
                            width:"190px",
                            color: "#FFC107",
                            backgroundColor: "#EFEFEF",
                            borderColor: "#EFEFEF",
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            borderRadius: '0',
                            fontSize: "12px"
                        }}
                        onClick={() => handleLancarNotasClick(subject.id, subject.name, subject.studentId)} 
                    >
                        Visualizar Avaliações
                        <EastIcon />
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}
