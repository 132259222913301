import { Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";

export default function CategoryForm() {
  const isDisabled=HandleUrl();
  const { keycloak } = useKeycloak();
  let page =1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/employee-category`;
  const baseRouteUrl = "/category";
  const [iNITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    name: "",
    description: "",
    id: "",
  });

  const { id } = useParams();
  const isUpdate = !!id;

  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);

  const FORM_VALIDATION = Yup.object().shape({
    description: Yup.string().required(i18n.t("messages.required")),
    name: Yup.string().required(i18n.t("messages.required")),
    id: Yup.string().optional(),
  });

  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    description: values.description,
  });

  const createItem = create(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Categoria criada com sucesso!");
  const updateItem = update(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Categoria atualizada com sucesso!");

  return (
    
      <div
        style={{
          backgroundColor: "white",
          padding: "80px",
          paddingTop: "55px",
        }}
      >
        <Formik
          initialValues={{
            ...iNITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            if (id) {
              updateItem(values);
            } else {
              createItem(values);
              console.log("values:", values);
            }
          }}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Typography variant="h1">
                {isUpdate ? values.name : i18n.t("category.titleCreate")}{" "}
              </Typography>
              <br />

              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <Textfield
                    name="name"
                    label={i18n.t("others.name")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Textfield
                    name="description"
                    label={i18n.t("others.description")}
                    disabled={isDisabled}
                    multiline
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2}>
              <Grid item xs={10}>
                <div
                 style={{ display: "flex", justifyContent: "flex-end",gap: "10px" ,paddingTop: "100px",}}
                >
            
                    <MainButton
                      url={baseRouteUrl}
                      name={i18n.t("button.cancel")}
                    />
                
                 
                    <AlertDialog
                      button={
                        id ? i18n.t("button.update") : i18n.t("button.register")
                      }
                      goBack={i18n.t("category.title")}
                      head={textBox[0]}
                      body={
                        textBox[1] || (
                          <FallBackLoader isLoading={status.isLoading} />
                        )
                      }
                      urlGoBack={baseRouteUrl}
                      stay={
                        id
                          ? i18n.t("others.category")
                          : i18n.t("category.titleCreate")
                      }
                      urlStay={
                        id ? `${baseRouteUrl}/${id}` : `${baseRouteUrl}/add`
                      }
                      type="submit"
                      isValid={true}
                      isLoading={status.isLoading}
                      disabled={isDisabled}
                    />
              
                </div>
              </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    
  );
}
