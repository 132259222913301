import React from 'react';
import { i18n } from '../../translate/i18n';
import './home.css';
import CustomCalendar from '../../components/canlender/calender';
import { Grid, Hidden, Typography } from '@mui/material';
import Card from '../../components/card/cards';
import Loading from '../../components/loading/loading';

export default function Home() {


  return (
    <Grid container display={""} style={{ height: '100vh' }}>
       <Hidden smDown>
      <Grid item style={{ flex: 1, marginBottom: '20px'}}>
        <Grid item>
          <Loading/>
        </Grid>
        <Grid item style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Typography variant='p'>{i18n.t('messages.home')}</Typography>
        </Grid>
      </Grid>
      </Hidden>
      <Grid
        item
        container
        xs={12}
        md={2}
        style={
          
          window?.innerWidth < 300
          ? { background: 'white'}
          : {background: 'white',   
          textAlign: 'center',
          height: 'calc(100vh - 30px)', // Ajuste a altura para considerar a margem
          position: 'absolute',
          right: 0,
          marginTop: '-50px',
        
 }
        }
      >
        <Typography variant="h3" sx={{ padding: "10px" }} >
          {i18n.t('others.NextE')}
        </Typography>
        <Grid  item
        container>
          <Card
            title="Workshop de Primeiros"

            addressIcon={<img src="icon/localização.svg" alt="Localização" />}
            addressText="123 Rua Exemplo"
            calendarIcon={<img src="icon/calendario.svg" alt="Calendario" />}
            calendarText="10 de dezembro de 2023"
            clockIcon={<img src="icon/hora.svg" alt="Hora" />}
            clockText="14:30"
          />

          <Card
            title="Meu Card"
            addressIcon={<img src="icon/localização.svg" alt="Localização" />}
            addressText="123 Rua Exemplo"
            calendarIcon={<img src="icon/calendario.svg" alt="Calendario" />}
            calendarText="10 de dezembro de 2023"
            clockIcon={<img src="icon/hora.svg" alt="Hora" />}
            clockText="14:30"
          />
        </Grid>
        <Grid xs={12} item>
        <hr style={{ margin: '10px 0', borderTop: '1px solid #ccc' }} />
        </Grid>
        <CustomCalendar style={{ marginTop: '20px' }} />
      </Grid>
    </Grid>
  );
}
