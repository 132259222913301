import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded} from "@mui/icons-material";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";
import statusNY from "../../assets/statusNY.svg"
import ConfirmationModal from "../../components/confirmModal/ConfirmationModal";

export default function SchoolYearListingPage() {
  const { keycloak } = useKeycloak();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [employeeIncomingData, setEmployeesIncomingData] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [newStatus, setNewStatus] = useState('');

  const CreateLesson = () => {
    navigate("/SchoolYear/create");
  };

  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setOpenDeleteModal(false);
  };
  

  const handleCloseStatusModal = () => {
    setStatusId(null);
    setOpenStatusModal(false);
  };


  const handleClickOpenDeleteModal = (id) => {
    console.log("Opening delete modal. ID:", id);
    setDeleteItemId(id);
    setOpenDeleteModal(true);
  };

  const handleClickOpenStatusModal = (id, newStatus) => {
    console.log("Opening status modal. ID:", id);
    setStatusId(id);
    setNewStatus(newStatus);
    setOpenStatusModal(true);
  };
  const getEmployee = async () => {
    setLoading(true);
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({ page: page });

    try {
      const response = await axios.get(
        `${apiUrl}/school-year?` + params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      setEmployeesIncomingData(response.data?.data);
      console.log(employeeIncomingData);
      if (localStorage.getItem("enployeeslPage")) setPage(response.data.page);
      setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    handleClickOpenStatusModal(id, newStatus);
  };

  const handleConfirmStatusChange = async (newStatus) => {
    try {
      const response = await axios.put(
        `${apiUrl}/school-year/${statusId}/status/${newStatus}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      if (response.status === 200) {
        getEmployee();
      } else {
        console.error(
          "Failed to update status. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleCloseStatusModal();
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getEmployee();
    }
  }, [keycloak.token, page]);

  const handleDisplayDataToVisualize = (id) => {
    navigate(`/SchoolYear/${id}?disabled=true`);
  };

  const handleEdit = (id) => {
    navigate(`/SchoolYear/${id}`);
  };

  const handleDeleteItem = async (id) => {
    console.log("Deleting item with ID:", id);
    setDeleteItemId(id);
    try {
      const response = await axios.delete(`/school-year/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        getEmployee();
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleCloseDeleteModal();
    }
  };
  

  const { format } = require('date-fns');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd');
  };

  const solveDate = (employeeIncomingData) => {
    let d = new Date(employeeIncomingData.startDate);
    let EndDate = new Date(employeeIncomingData.endDate);
    return (
      employeeIncomingData.startDate &&
      d.getFullYear() +
      (employeeIncomingData.endDate && "-" + EndDate.getFullYear())
    );
  };

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          alignItems: { xs: "left", md: "initial" },
          boxShadow: "none",
        }}
      >
        {loading && <Loading />}
        <Grid item xs={12}>
          {loading ? null : (
            <TableContainer sx={{ overflowX: "auto", border: "1px solid #E9ECF5" }}>
              <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" colSpan={5} style={{ height: "59px" }}>
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {i18n.t("sidebar.Leave")}

                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Box sx={{ width: "300px" }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              search={search}
                              setSearch={setSearch}
                              sx={{ width: "300px" }}
                            />
                          </Box>

                          <Box sx={{ width: "100px", marginLeft: "20px" }}>
                            <Select
                              onChange={(e) => { }}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Page size",
                              }}
                              sx={{
                                width: "80px",
                                height: "40px",
                              }}
                            >
                              {[5, 10, 20, 50, 100].map((size) => (
                                <MenuItem key={size} value={size}>
                                  {size}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "120px",
                              height: "40px",
                              border: "1px solid #C3CAD9",
                              marginRight: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            <FilterList
                              cardStyle={{
                                position: "absolute",
                                zIndex: "1",
                                top: "140px",
                                right: "323px",
                              }}
                              filters={[]}
                              setCanFilter={() => { }}
                              canFilter={false}
                            />
                            {i18n.t("others.filter")}
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              sx={{
                                height: "35px",
                                width: "80px",
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",

                                "&:hover": {
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                },
                              }}
                              onClick={CreateLesson}
                            >
                              <AddBoxRounded />
                              {i18n.t("button.new")}
                            </Button>
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: "#F8FAFD",
                      height: "56px",
                      color: "#939a9f",
                    }}
                  >
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("sidebar.Leave")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.startDate")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.endDate")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="center">
                      {i18n.t("others.status")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="center">
                      {i18n.t("others.options")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeIncomingData.map((data, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{solveDate(data)}</TableCell>
                      <TableCell align="left">{formatDate(data.startDate)}</TableCell>
                      <TableCell align="left">{formatDate(data.endDate)}</TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="center">
                          {data.status}
                          <button
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                              padding: "0",
                            }}
                            onClick={() => {
                              let newStatus;
                              if (data.status === "Em criação") {
                                newStatus = "in_progress";
                              } else if (data.status === "A decorrer") {
                                newStatus = "completed";
                              } else {
                                newStatus = "creating";
                              }
                              handleStatusChange(data.id, newStatus);
                            }}
                          >
                            <img
                              src={statusNY}
                              alt="Status Icon"
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            />
                          </button>

                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <ActionButtons
                          id={data.id}
                          handleVisualize={handleDisplayDataToVisualize}
                          handleEdit={handleEdit}
                          handleDeleteItem={() => handleClickOpenDeleteModal(data.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

      {!loading && employeeIncomingData?.length ? (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}

      {deleteItemId && (
        <AlertDialogForConfirm
          open={openDeleteModal}
          id={deleteItemId}
          handleClose={handleCloseDeleteModal}
          handleDeleteItem={handleDeleteItem}
        />
      )}
      {statusId && (
        <ConfirmationModal
          open={openStatusModal}
          handleClose={handleCloseStatusModal}
          handleConfirm={() => handleConfirmStatusChange(newStatus)}
          newStatus={newStatus}
        />
      )}
    </>
  );
}
