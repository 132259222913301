

import { useKeycloak } from "@react-keycloak/web";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import { Grid } from "@mui/material";
import BasicCard from "./components/BasicCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading";



export default function Enrolls() {
    const apiUrl = `${process.env.REACT_APP_API_URL}/courses/from-school-year`;
    let page=1;
    const [courses, setCourses] = useState([]);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    
    const isSecretaria =
    keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
      "Secretaria"
    );
    if(!isSecretaria)
    navigate(`/enrolls/all`)

    const getItem = get(
        `${apiUrl}`,
        keycloak,
        setCourses,
        undefined,
        page
      );
      useEffect(() => {
        
          getItem();
          }, []);

    if(isSecretaria==undefined || !isSecretaria) 
   return <Loading />

  return (
    <Grid>
        <Grid item xs={12}>
            <h1 style={{
                  fontSize: "20px",
                  color: "#212529",
                }}>Matrículas</h1>
            <h1 style={{
                  fontSize: "18px",
                  color: "#212529",
                }}>1ª Matrículas</h1>
            <h1 style={{
                  fontSize: "18px",
                  color: "#F1C61B",
                }}>Disponíveis acedidas</h1>


        </Grid>
        <Grid item xs={12}>
            {courses.map((course) => (
                <Grid item xs={12} key={course.id}>
                    <BasicCard
                    title={course.name}
                    description={course.description}
                    id={course.id}
                    NuMat={course.totalStudents}
                    />
                </Grid>
            ))}
        </Grid>

        <Grid item xs={12} style={{ marginTop: "40px" }}>
          <h1 style={{
                fontSize: "18px",
                color: "#212529",
              }}>Renovação de Matrículas</h1>
          <h1 style={{
                fontSize: "18px",
                color: "#F1C61B",
              }}>Inscreva-se às disciplinas!</h1>


      </Grid>
    </Grid>
    
  );
}