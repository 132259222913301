import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography,Box} from "@mui/material";
import Collapsible from '../../../components/collapsiTable/Collapsible';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';

const EnrolledSubjects = ({ enrollId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const [subjects, setSubjects] = useState([]);

  // Função para buscar disciplinas inscritas e reprovadas
  const fetchSubjects = async () => {
      try {
          const response = await axios.get(`${apiUrl}/enroll/${enrollId}/renew/subjects`, {
              headers: {
                  Authorization: `Bearer ${keycloak.token}`,
              },
          });

          // Inicializa listas de disciplinas para o próximo ano e disciplinas reprovadas
          const nextYearSubjects = response.data.nextYear || [];
          const reprovesInPreviousYearSubjects = response.data.reprovesInPreviousYear || [];

          // Combina as listas
          const combinedSubjects = [...nextYearSubjects, ...reprovesInPreviousYearSubjects];

          // Define a lista combinada como o estado de subjects
          setSubjects(combinedSubjects);
      } catch (error) {
          console.error("Erro ao buscar disciplinas inscritas e reprovadas:", error);
      }
  };

  useEffect(() => {
      fetchSubjects();
  }, [enrollId]);

  // Função para calcular o ano acadêmico com base no semestre
  const calculateAcademicYear = (semester) => {
      return Math.ceil(semester / 2);
  };

  // Função para organizar os dados em uma estrutura hierárquica com ano, semestre e disciplina
  const prepareCollapsibleData = (subjects) => {
      // Inicializa um objeto para agrupar disciplinas por ano
      const academicYearGroups = {};

      // Agrupa disciplinas por ano acadêmico e semestre
      subjects.forEach((subjectItem) => {
          const semester = subjectItem.semester;
          const academicYear = calculateAcademicYear(semester);

          // Se o ano acadêmico ainda não está no objeto, cria uma nova entrada
          if (!academicYearGroups[academicYear]) {
              academicYearGroups[academicYear] = {};
          }

          // Se o semestre ainda não está no objeto, cria uma nova entrada
          if (!academicYearGroups[academicYear][semester]) {
              academicYearGroups[academicYear][semester] = [];
          }

          // Adiciona a disciplina ao grupo correspondente
          academicYearGroups[academicYear][semester].push({
              name: subjectItem.subject.name, // Nome da disciplina
          });
      });

      // Transforma o objeto em uma lista de itens colapsáveis por ano acadêmico
      return Object.keys(academicYearGroups).map((academicYear) => ({
          name: `${academicYear}º Ano`, // Nome do ano acadêmico
          items: Object.keys(academicYearGroups[academicYear]).map((semester) => ({
              name: `${semester}º Semestre`, // Nome do semestre
              items: academicYearGroups[academicYear][semester], // Lista de disciplinas do semestre
          })),
      }));
  };

  // Função para renderizar o conteúdo de cada célula
  const extractCellContent = (item) => (
      <Box>
          {item.name}
      </Box>
  );

  return (
      <Grid
          container
          component={Paper}
          sx={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              position: "relative",
              alignItems: { xs: "left", md: "initial" },
              boxShadow: "none",
              padding: 2,
          }}
      >
          <h2 style={{ textAlign: "justify", color: "#FFC107", fontSize: "20px" }}>
              Renovação de matrícula
          </h2>
          <Typography sx={{ textAlign: 'justify', color: "#949494" }} variant='p'>
              Seja bem-vindo ao processo de matrícula no 1º ano do curso de Medicina da Faculdade de Medicina da Universidade de Agostinho Neto, Angola.
          </Typography>
          <Typography sx={{ paddingTop: "30px", paddingBottom: "20px", color: "#003B76" }} variant='h3'>
              Disciplinas Inscritas
          </Typography>

          <Collapsible
              items={prepareCollapsibleData(subjects)}
              extractCellContent={extractCellContent}
              levels={['items', 'items']} // Defina os níveis de hierarquia
          />
      </Grid>
  );
};

export default EnrolledSubjects;
