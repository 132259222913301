export function removeAccents(str) {
   return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const transformScheduleData = (data, days, times) => {
   const scheduleMap = {};
   // Inicializa o mapa com todas as combinações possíveis de dia e horário
   days?.forEach((day) => {
      times.forEach((time) => {
         const key = `${day.id}#${time.id}`;
         scheduleMap[key] = {
            groupId: null,
            classroomId: null,
            disciplineId: null,
         };
      });
   });

   // Preenche o mapa com os dados fornecidos
   data &&
      data?.forEach((slot) => {
         const key = `${slot.teachingDays?.id}#${slot.teachingTime?.id}`;
         if (scheduleMap[key]) {
            scheduleMap[key].groupId = slot.group ? slot.group?.id : null;
            scheduleMap[key].classroomId = slot.classroom.name || null;
            scheduleMap[key].disciplineId = slot.subject ? slot.subject?.id : null;
         }
      });

   return scheduleMap;
};

export const updateScheduleWithNewData = (currentSchedule, newData) => {
   let updatedSchedule = { ...currentSchedule };

   Object.entries(newData).forEach(([combinedKey, newValues]) => {
      const [teachingDayId, teachingTimeId] = combinedKey.split("#");
      const scheduleKey = `${teachingDayId}#${teachingTimeId}`;

      if (updatedSchedule[scheduleKey]) {
         Object.entries(newValues).forEach(([key, value]) => {
            if (value !== null) {
               updatedSchedule[scheduleKey][key] = value;
            }
         });
      } else {
         updatedSchedule[scheduleKey] = newValues;
      }
   });

   return updatedSchedule;
};
