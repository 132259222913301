
import { Box, Button, Grid, Select, Typography, styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import * as React from "react";
import { i18n } from "../../translate/i18n";
import { useState,useEffect } from "react";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import {  useNavigate } from "react-router-dom";
import { AddBoxRounded } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import FilterList from "../../components/filterList/FilterList";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";
import CustomPagination from "../../components/pagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

export default function LessonPage() {
  const [lessonss, setLessonss] = useState([]);
  const { keycloak } = useKeycloak();
  const [search, setSearch] = useState("");
  const [page, pageSeter] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const pathUrl = "/lessons";
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClickOpen = (id) => {
    console.log("Opening modal. ID:", id);
    setDeleteItemId(id);
    setOpen(true);
  };
  const setPage = (page) => {
    pageSeter(parseInt(page));
    localStorage.setItem("enrollPage", page);
  };

  const handleClose = () => {
    console.log("Closing modal");
    setDeleteItemId(null);
    setOpen(false);
  };
  const CreateLesson = () => {
    navigate("/lessons/create");
  };

  useEffect(() => {
    const searchGraduations = async (searchTerm) => {
      try {
        const response = await axios.get(
          `${apiUrl}/classrooms?search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );

        setSearchResults(
          response.data?.data.map((depart) => ({
            ...depart,
          }))
        );

        return response.data;
      } catch (error) {
        console.error("Error searching departments:", error);
        throw error;
      }
    };

    const delaySearch = setTimeout(() => {
      if (search.trim() !== "") {
        searchGraduations(search);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [search, keycloak.token]);

  const handleDeleteItem = async (id) => {
    try {
      const response = await axios.delete(`/classrooms/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        console.log("Employee deleted successfully:", response);
        setLessonss(lessonss.filter((lesson) => lesson.id !== id));
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    } finally {
      handleClose(); // Move handleClose to the end to ensure it's called even if there's an error
    }
  };

  const handleDisplayDataToVisualize = (id) => {
    navigate(`${pathUrl}/${id}?disabled=true`);
  };

  const handleEdit = (id) => {
    navigate(`${pathUrl}/${id}/edit`);
  };

  const getClass = async () => {
    setLoading(true);

    // Return early if page is falsy
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({ page: page });

    try {
      const response = await axios.get(
        `${apiUrl}/classrooms?` + params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      setLessonss(response.data?.data);

      if (localStorage.getItem("enrollPage")) {
        setPage(response.data.page);
      }

      setTotalPages(response.data.meta.totalPages);
      console.log(totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getClass();
    }
  }, [keycloak.token, page]);

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          alignItems: { xs: "left", md: "initial" },
          boxShadow: "none",
        }}
      >
        {loading && <Loading />}
        <Grid item xs={12}>
          {loading ? null : ( // Render nothing while loading
            <TableContainer
              sx={{ overflowX: "auto", border: "1px solid #E9ECF5" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a lesson table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      colSpan={4}
                      style={{ height: "59px" }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {i18n.t("sidebar.Classroom")}

                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Box sx={{ width: "300px" }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              search={search}
                              setSearch={setSearch}
                              handler={() => setSearch("")}
                              sx={{ width: "300px" }}
                            />
                          </Box>

                          <Box sx={{ width: "100px", marginLeft: "20px" }}>
                            <Select
                              onChange={() => {}}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Page size",
                              }}
                              sx={{
                                width: "80px",
                                height: "40px",
                              }}
                            >
                              {[5, 10, 20, 50, 100].map((size) => (
                                <MenuItem key={size} value={size}>
                                  {size}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "120px",
                              height: "40px",
                              border: "1px solid #C3CAD9",
                              marginRight: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            <FilterList
                              cardStyle={{
                                position: "absolute",
                                zIndex: "1",
                                top: "140px",
                                right: "323px",
                              }}
                              filters={[]}
                              setCanFilter={() => {}}
                              canFilter={false}
                            />
                            {i18n.t("others.filter")}
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              sx={{
                                height: "35px",
                                width: "80px",
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",

                                "&:hover": {
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                  // Add other hover styles as needed
                                },
                              }}
                              onClick={CreateLesson}
                            >
                              <AddBoxRounded />
                              {i18n.t("button.new")}
                            </Button>
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow height="56px" sx={{ backgroundColor: "#F8FAFD" }}>
                    <StyledTableCell
                      sx={{ color: "#939a9f", width: "30%" }}
                      align="left"
                    >
                      {i18n.t("others.name")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {i18n.t("others.TypeofRoom")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {i18n.t("others.cap")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ color: "#939a9f", width: "20%" }}
                      align="center"
                    >
                      {i18n.t("others.options")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(search.trim() === "" ? lessonss : searchResults).map(
                    (data) => (
                      <TableRow key={data.id}>
                        <TableCell align="left">{data.name}</TableCell>
                        <TableCell align="left">
                          {data.roomtype?.name}
                        </TableCell>
                        <TableCell align="left">{data.capacity}</TableCell>
                        <TableCell align="center">
                          <ActionButtons
                            id={data.id}
                            handleVisualize={handleDisplayDataToVisualize}
                            handleEdit={handleEdit}
                            handleDeleteItem={() => handleClickOpen(data.id)} // Ensure correct ID is passed
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

      {!loading && (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <CustomPagination
            setPage={setPage}
            totalPages={totalPages}
            page={page}
          />
        </Grid>
      )}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={handleDeleteItem}
        />
      )}
    </>
  );
}
