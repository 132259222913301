import React from "react";
import { Box, Button } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function FilterComponet({ year, semester }) {
   return (
      <Box sx={{ display: "flex", gap: "10px" }}>
         <Box sx={{ color: "#42474B", font: "word sans", display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon sx={{ width: "20px" }} />
            {year}º {i18n.t("others.year")}
            <KeyboardArrowRightIcon sx={{ width: "20px" }} />
         </Box>

         <Box sx={{ color: "#42474B", font: "word sans", display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon sx={{ width: "20px" }} />
            {semester}º {i18n.t("others.semester")}
            <KeyboardArrowRightIcon sx={{ width: "20px" }} />
         </Box>
      </Box>
   );
}
