import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import HandleUrl from '../../../utils/HandleUrl';
import { i18n } from '../../../translate/i18n';
import Textfield from '../../../components/form-components/text-field';
import DateTimePicker from '../../../components/form-components/date-time-picker';
import { useState } from 'react';

export default function PersonalInfo(errors, touched) {
  const [isDisabled, setIsDisabled] = useState(HandleUrl());

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} marginBottom={2}>
        <Typography sx={{ color: '#003B76' }} variant='h3'>
          {i18n.t('others.personalInfo')}
        </Typography>
      </Grid>

      <Grid item xs={12} container spacing={3}>
        <Grid item xs={5}>
          <Textfield
            name='employeeNumber'
            type='number'
            label={i18n.t('others.idCard')}
            disabled={isDisabled}
            error={errors?.errors?.employeeNumber}
          />
        </Grid>

        <Grid item xs={5}>
          <Textfield
            name='firstName'
            label={i18n.t('others.firstName')}
            disabled={isDisabled}
            error={errors?.errors?.firstName}
          />
        </Grid>

        <Grid item xs={5}>
          <Textfield
            name='lastName'
            label={i18n.t('others.lastName')}
            disabled={isDisabled}
            error={errors?.errors?.lastName}
          />
        </Grid>

        <Grid item xs={3}>
          <DateTimePicker
            name='birthdate'
            label={i18n.t('others.birthdate')}
            disabled={isDisabled}
            error={errors?.errors?.birthdate}
          />
        </Grid>
        <Grid item xs={5}>
          <Textfield
            name='nif'
            label={i18n.t('others.Nif')}
            disabled={isDisabled}
            error={errors?.errors?.nif}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
