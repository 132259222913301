import axios from "axios";
import { i18n } from "../../translate/i18n";
import { getErrorMessage, handleFailure } from "./utilFunctions";

// utils/functions/formCRUDFunctions.js

export const createItem =
  (formatFormData, endpoint, setTextBox, setStatus, keycloak, resourceName) => (values) => {
    try {
      const formData = formatFormData(values);
      const headers =
        typeof keycloak === "object"
          ? {
              headers: {
                Authorization: "Bearer " + keycloak.token,
              },
            }
          : {};

      axios
        .post(endpoint, formData, headers)
        .then(function (response) {
          if (response && response?.status <= 201) {
            console.log("Successfully saved! ", response);
            console.log("Saving Data: ", formData);

            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t(`${resourceName}`);
              return textCopy;
            });
            setStatus((prev) => ({ ...prev, isLoading: false }));
          } else {
            setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
            setStatus((prev) => ({ ...prev, isLoading: false }));
          }
        })
        .catch(function (error) {
          setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
          setStatus((prev) => ({ ...prev, isLoading: false }));
        });
    } catch (error) {
      setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
      console.log("error saving: ", error);
    }
  };

export const updateItem =
  (formatFormData, endpoint, setTextBox, setStatus, keycloak, resourceName) => (values) => {
    try {
      const formData = formatFormData(values);
      const headers =
        typeof keycloak === "object"
          ? {
              headers: {
                Authorization: "Bearer " + keycloak.token,
              },
            }
          : {};

      console.log("ENDPOINT: ", `${endpoint}/${values.id}`);
      axios
        .put(`${endpoint}/${values.id}`, formData, headers)
        .then(function (response) {
          if (response && response?.status <= 201) {
            console.log("Successfully updated! ", response);
            console.log("Updating Data: ", formData);

            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t(`${resourceName}`);
              return textCopy;
            });
            setStatus((prev) => ({ ...prev, isLoading: false }));
          } else {
            setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
            setStatus((prev) => ({ ...prev, isLoading: false }));
          }
        })
        .catch(function (error) {
          setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
          setStatus((prev) => ({ ...prev, isLoading: false }));
        });
    } catch (error) {
      setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
      console.log("error updating: ", error);
    }
  };

//Fetch all subjects, without pagination limit
export const fetchAllItems =
  (keycloak) => async (apiUrl, setter, customSetFunction) => {
    try {
      const assets = [];
      let page = 1;
      let hasMore = true;
      let headers = {};

      if (typeof keycloak === "object") {
        headers = {
          Authorization: "Bearer " + keycloak.token,
        };
      }

      while (hasMore) {
        const response = await axios.get(`${apiUrl}?page=${page}`, { headers });
        const resp = response.data?.data ? response.data.data : response.data;

        if (resp?.length > 0) {
          assets.push(...resp);
          page++;
        } else {
          hasMore = false;
        }
      }

      if (
        typeof setter === "function" &&
        typeof customSetFunction === "function"
      ) {
        setter(customSetFunction(assets));
      } else {
        setter(assets);
      }
      return assets;
    } catch (error) {
      console.error(error);
      setter([]);
      return [];
    }
  };
