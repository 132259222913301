import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CardContent, Grid, Typography, Button } from "@mui/material";
import MultipleSelect from "../select/select";
import { i18n } from "../../translate/i18n";
import { useState } from "react";
import PropTypes from "prop-types";

export default function FilterList({ cardStyle, filters = undefined, setCanFilter, canFilter }) {
   const [isCardOpen, setIsCardOpen] = useState(false);

   const handleIconButtonClick = () => {
      setIsCardOpen(!isCardOpen);
   };

   return (
      <>
         {canFilter ? (
            <IconButton
               onClick={() => {
                  setCanFilter(false);
                  filters.map((filter) => filter.setSelectedOptions([]));
               }}
               disableRipple
            >
               <CloseIcon fontSize="large" />
            </IconButton>
         ) : (
            <IconButton onClick={handleIconButtonClick} disableRipple>
               <FilterListIcon fontSize="large" style={{ background: "white" }} />
            </IconButton>
         )}
         <div
            style={{
               position: "absolute",
               height: "100vh",
               top: 0,
               right: -280,
            }}
         >
            {isCardOpen && (
               <Card sx={{ minWidth: 295, ...cardStyle }}>
                  <CardContent style={{ display: "column", width: "full", padding: "0px" }}>
                     <Grid
                        style={{
                           width: "100%",
                           display: "flex",
                           justifyContent: "space-between",
                           border: "1px solid #E9ECF5",
                           borderRadius: "5px",
                        }}
                     >
                        <Typography style={{ margin: "12px" }}>
                           {" "}
                           {filters?.length ? i18n.t("others.filterBy") : i18n.t("others.noFilters")}{" "}
                        </Typography>

                        <IconButton onClick={handleIconButtonClick}>
                           <CloseIcon fontSize="large" />
                        </IconButton>
                     </Grid>
                     <Grid>
                        {Array.isArray(filters) && filters.length
                           ? filters.map((filter) => (
                                <MultipleSelect
                                   key={filter?.label}
                                   label={filter?.label}
                                   options={filter?.options}
                                   selectedOptions={filter?.selectedOptions}
                                   setSelectedOptions={filter?.setSelectedOptions}
                                />
                             ))
                           : null}
                     </Grid>
                     {filters?.length ? (
                        <Grid
                           style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              marginTop: "20px",
                              marginBottom: "20px",
                           }}
                        >
                           <Box>
                              <Button
                                 variant="text"
                                 sx={{
                                    color: "#FFC107",
                                    padding: 1,
                                    border: "1px solid #FFC107",
                                 }}
                                 onClick={() => {
                                    handleIconButtonClick();
                                    setCanFilter(false);
                                    filters.map((filter) => filter.setSelectedOptions([]));
                                 }}
                              >
                                 {i18n.t("button.cancel")}
                              </Button>
                           </Box>
                           <Box>
                              <Button
                                 variant="text"
                                 sx={{
                                    padding: 1,
                                    color: "white",
                                    backgroundColor: "#FFC107",
                                 }}
                                 onClick={() => {
                                    handleIconButtonClick();
                                    setCanFilter(true);
                                 }}
                              >
                                 {i18n.t("button.filter")}
                              </Button>
                           </Box>
                        </Grid>
                     ) : null}
                  </CardContent>
               </Card>
            )}
         </div>
      </>
   );
}
FilterList.propTypes = {
   cardStyle: PropTypes.object,
   filters: PropTypes.arrayOf(
      PropTypes.shape({
         label: PropTypes.string.isRequired,
         options: PropTypes.array.isRequired,
         selectedOptions: PropTypes.array.isRequired,
         setSelectedOptions: PropTypes.func.isRequired,
      })
   ),
   setCanFilter: PropTypes.func.isRequired,
   canFilter: PropTypes.bool.isRequired,
};
