import PersonalInfo from './components/PersonalInfo';
import Contact from './components/Contact';
import HiringDetails from './components/HiringDetails';
import Payment from './components/Payment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Alert, Grid, Snackbar, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AlertDialog from '../../components/dialog-option/AlertDialog';
import { i18n } from '../../translate/i18n';
import HandleUrl from '../../utils/HandleUrl';
import GenericButton from '../../components/simple-button';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Upload from './components/Upload';
import { useKeycloak } from '@react-keycloak/web';
import FallBackLoader from '../../components/FallBackComponents/FallBackLoader';
import {
  getErrorMessage,
  handleFailure,
} from '../../utils/functions/utilFunctions';

export default function EmployeeCreatePage() {
  const steps = [
    'others.personalInfo',
    'others.contact',
    'employee.hiringDetails',
    'others.DocumentsAttached',
    'employee.paymentDetails',
  ];
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  let setPage = 0;
  const [totalPages, setTotalPages] = useState();
  const [incomingDate, setIncomingDate] = useState([]);
  let isDisabled = HandleUrl();
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState({});
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const isUpdate = !!id;
  const [isProfessor, setIsProfessor] = useState(false);
  const [selectedDisciplinas, setSelectedDisciplinas] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([
    i18n.t(id ? 'messages.updateTitle' : 'employee.mc'),
    '',
  ]);
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    contractType: {
      id: '',
    }, // Deixe vazio inicialmente
    employeeCategories: [
      {
        id: '',
      },
    ],
    employeeRoles: [
      {
        id: '',
      },
    ],
  });

  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  // STEPPER CUSTOMIZATION START -------------------------------------------

  const ColorlibConnector = styled(StepConnector)(({ theme, ownerState }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },

    ...(ownerState.orientation === 'vertical' &&
      ownerState.color === 'primary' && {
        backgroundColor: '#202020',
        color: '#fff',
      }),
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#FFC107',
    border: '1px solid #ccc',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: '#FFC107',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      border: '1px solid #FFC107',
    }),
    ...(ownerState.completed && {
      backgroundColor: '#FFC107',
      border: '1px solid #FFC107',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      ></ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  // STEPPER CUSTOMIZATION END -------------------------------------------

  const handleNextStep = (values, errors, touched, setErrors) => {
    const currentSchema = formschema[activeStep];
    currentSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch(async (err) => {
        console.error(err);
        await setErrors(
          err.inner.reduce(
            (acc, error) => ({ ...acc, [error.path]: error.message }),
            {}
          )
        );
      });

    if (Object.keys(errors).length !== 0) {
      setOpenSnack(true);
    }
    console.log('Errors:', errors);
  };

  const handlePreviousStep = () => {
    if (activeStep !== 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
    console.log('Previous Step:', activeStep - 1);
  };

  const [toast, setToast] = useState({ message: '', severity: 'success' });
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s });
    setTimeout(() => setOpen(true), 200);
  };
  // -----------------------------------------_Developing with FORMIK _-------------------------------------------

  const formschema = [
    Yup.object().shape({
      employeeNumber: Yup.number()
        .integer()
        .required(i18n.t('messages.required')),
      firstName: Yup.string().required(i18n.t('messages.required')),
      lastName: Yup.string().required(i18n.t('messages.required')),
      birthdate: Yup.date().required(i18n.t('messages.required')),
      nif: Yup.string().required(i18n.t('messages.required')),
    }),
    Yup.object().shape({
      phone: Yup.string()
        .min(9, 'O telefone deve ter minimo 9 numeros')
        .max(9, 'O telefone deve ter maximo 9 numeros')
        .required(i18n.t('messages.required')),
      personalEmail: Yup.string()
        .email('Invalid email')
        .required(i18n.t('messages.required')),
      institutionalEmail: Yup.string()
        .email('Invalid email')
        .required(i18n.t('messages.required')),
      address: Yup.string().required(i18n.t('messages.required')),
    }),
    Yup.object().shape({
      // department: Yup.string().required(i18n.t('messages.required')),
      // contractType: Yup.string().required(i18n.t('messages.required')),
      // employeeCategories: Yup.string().required('Required!'),
      // employeeRoles: Yup.string().required('Required!'),
      startFunction: Yup.date().required(i18n.t('messages.required')),
      endFunction: Yup.date().required(i18n.t('messages.required')),
    }),
    Yup.object().shape({}),
    Yup.object().shape({
      mainBank: Yup.string().required('Required!'),
      mainAccountNumber: Yup.number()
        .integer()
        .required(i18n.t('messages.required')),
      mainNib: Yup.string().required(i18n.t('messages.required')),
      // secondaryBank: Yup.string().required(i18n.t('messages.required')),
      // secondaryNib: Yup.string().required(i18n.t('messages.required')),
      // secondaryAccountNumber: Yup.number()
      // .integer()
      // .required(i18n.t('messages.required')),
      salary: Yup.number().integer().required(i18n.t('messages.required')),
    }),
  ];

  const uploadFile = async (files, id, type, responseData) => {
    try {
      const formData = new FormData();
      formData.append('entityId', id);

      formData.append('DocIdentification', files['DocIdentification']); // O nome 'file' deve corresponder ao esperado pela API
      formData.append('AccountNumber', files['AccountNumber']);
      formData.append('WorkContract', files['WorkContract']);
      formData.append('DegreeCertificate', files['DegreeCertificate']);
      formData.append('MasterCertificate', files['MasterCertificate']);

      const response = await axios.post(
        `${apiUrl}/employee-attachment`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token, // token de autenticação desgraçado
          },

          'Content-Type': 'multipart/form-data', // Importante para enviar arquivos
        }
      );

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log('Resposta da Requisição de Upload:', response);

      handleClick('Ficheiros Upploaded Com Successo', 'success');

      // Você pode atualizar o estado com o ID da imagem ou outras informações relevantes da resposta
      //setImageId(response.data.imageId);
    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);
    }
  };

  const postEmployees = async (values) => {
    try {
      setStatus({ isLoading: true });

      values.departments = selectedDepartment.map((department) =>
        String(department.id)
      );
      values.subjects = selectedDisciplinas.map((disciplina) =>
        String(disciplina.id)
      );

      if (!Array.isArray(values.employeeRoles)) {
        values.employeeRoles = [values.employeeRoles];
      }
      if (!Array.isArray(values.employeeCategories)) {
        values.employeeCategories = [values.employeeCategories];
      }
      let payload = values;

      payload.contractType = values.contractType.id;
      if (
        Array.isArray(values.employeeRoles) &&
        values.employeeRoles.length > 0
      ) {
        payload.employeeRoles = [values.employeeRoles[0].id];
      }
      if (
        Array.isArray(values.employeeCategories) &&
        values.employeeCategories.length > 0
      ) {
        payload.employeeCategories = [values.employeeCategories[0].id];
      }

      if (
        !selectedDepartment.length > 0 ||
        !payload.employeeCategories.length > 0 ||
        !payload.employeeRoles.length > 0
      ) {
        setTextBox([i18n.t('messages.wrong'), i18n.t('employee.formError')]);
        return;
      }

      const response = await axios.post(`${apiUrl}/employees`, payload, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });

      if (response && response?.status <= 201) {
        setTextBox((prev) => {
          const textCopy = [...prev];
          textCopy[1] = i18n.t('messages.createMessage');
          return textCopy;
        });
        setStatus((prev) => ({ ...prev, isLoading: false }));
        uploadFile(
          files,
          response.data?.id,
          values?.documentType,
          response.data
        );
      } else {
        setTextBox([i18n.t('messages.wrong'), handleFailure(response)]);
        setStatus((prev) => ({ ...prev, isLoading: false }));
      }

      if (response && response.data && response.data.data) {
        console.log('Resposta da Requisição:', response);
        setIncomingDate([...response.data.data]);
        setPage(response?.data?.page);
        setTotalPages(response?.data?.meta?.totalPages);
      }
    } catch (error) {
      setTextBox([i18n.t('messages.wrong'), getErrorMessage(error)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      // Se você está atualizando um funcionário (com base no ID presente na URL), faça uma solicitação GET para obter os dados do funcionário.
      axios
        .get(`${apiUrl}/employees/${id}`, {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        })
        .then((response) => {
          console.log('hoje é dia de deta durmi');

          setINITIAL_FORM_STATE(response.data);
          setSelectedDepartment(response.data.departments);
          setSelectedDisciplinas(response.data.subjects);
        })
        .catch((error) => {
          console.error('Erro ao buscar dados do funcionário:', error);
        });
    }
  }, [id]);

  function updateEmployees(values) {
    try {
      setStatus({ isLoading: true });
      // Mapeia os valores necessários para o formato adequado antes de enviar a requisição
      values.departments = selectedDepartment.map(
        (department) => department.id
      );
      values.contractType = values.contractType
        ? values.contractType.id
        : undefined;
      values.employeeRoles = values.employeeRoles.map((role) => role.id);
      values.employeeCategories = values.employeeCategories.map(
        (category) => category.id
      );
      values.subjects = selectedDisciplinas.map((subject) =>
        String(subject.id)
      );

      // Desestruturação para remover propriedades indesejadas antes de enviar a requisição
      const {
        id,
        createdDate,
        updatedDate,
        deletedDate,
        coordinated_courses,
        periods,
        userAccountID,
        ...updatedValues
      } = values;

      // Faz a requisição PUT para atualizar o funcionário no servidor
      axios
        .put(`${apiUrl}/employees/${id}`, updatedValues, {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        })
        .then(function (response) {
          if (response.status <= 201) {
            console.log('Successfully created! ', response.data);
            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t('messages.updateMessage');
              return textCopy;
            });
            setStatus({ isLoading: false });
            uploadFile(
              files,
              response.data?.id,
              values?.documentType,
              response.data
            );
          } else {
            setTextBox([i18n.t('messages.wrong')], handleFailure(response));
          }
        })
        .catch(function (error) {
          setTextBox([i18n.t('messages.wrong'), getErrorMessage(error)]);
          setStatus({ isLoading: false });
        });
    } catch (error) {
      setTextBox([i18n.t('messages.wrong'), getErrorMessage(error)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    }
  }

  console.log('initial form state', INITIAL_FORM_STATE);
  return (
    <Grid container>
      {!id && (
        <Grid item xs={12} marginBottom={3}>
          <Typography variant='h1'>Criar um novo funcionário</Typography>
        </Grid>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key='errorForm'
      >
        <Alert
          onClose={handleClose}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          Preencha todos os campos.
        </Alert>
      </Snackbar>

      <Grid
        item
        container
        sx={{ backgroundColor: '#FFFFFF', padding: '80px', paddingTop: '55px' }}
        xs={12}
      >
        <Grid item xs={3}>
          <Stepper
            activeStep={activeStep}
            sx={{ my: '1rem' }}
            orientation='vertical'
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    {...labelProps}
                    StepIconComponent={ColorlibStepIcon}
                    onClick={() => setActiveStep(index)} // Add this onClick handler
                  >
                    {i18n.t(label)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>

        <Grid item xs={9} paddingTop={3}>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={formschema[activeStep]}
            onSubmit={(values) => {
              console.log('Início da Função onSubmit');
              console.log('Valores do Formulário:', values);
              if (id) {
                // Chame a função de atualização
                updateEmployees(values);
              } else {
                // Chame a função de criação
                postEmployees(values);
              }

              console.log(values);
            }}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              //handleChange,
              // handleBlur,
              // handleSubmit,
              isSubmitting,
              isValid,
              setErrors,
              /* and other goodies */
            }) => (
              <Form>
                {activeStep === steps.length ? (
                  (console.log('Valor de activeStep:', activeStep),
                  (<React.Fragment></React.Fragment>))
                ) : (
                  <React.Fragment>
                    <div>
                      {activeStep === 0 && <PersonalInfo errors={errors} />}
                      {activeStep === 1 && <Contact errors={errors} />}
                      {activeStep === 2 && (
                        <HiringDetails
                          values={INITIAL_FORM_STATE}
                          isProfessor={isProfessor}
                          setIsProfessor={setIsProfessor}
                          selectedDisciplinas={selectedDisciplinas}
                          setSelectedDisciplinas={setSelectedDisciplinas} // Adicione esta linha
                          setSelectedDepartment={setSelectedDepartment}
                          selectedDepartment={selectedDepartment}
                        />
                      )}
                      {activeStep === 3 && (
                        <Upload setFiles={setFiles} files={files} id={id} />
                      )}
                      {activeStep === 4 && <Payment errors={errors} />}
                    </div>
                    <Grid container spacing={3}>
                      <Grid marginTop={11}>
                        <div
                          style={{
                            display: 'flex',
                            gap: '65px',
                            paddingLeft: '460px',
                            paddingTop: '130px',
                          }}
                        >
                          <Grid item xs={2}>
                            <GenericButton
                              disabled={activeStep === 0}
                              handler={handlePreviousStep}
                              variant='outlined'
                              label={i18n.t('button.previous')}
                              sx={{ color: '#FFC107', marginRight: '70px' }}
                            />
                          </Grid>

                          <Grid textAlign={'right'} item xs={2}>
                            <Box>
                              {activeStep === steps.length - 1 ? (
                                <AlertDialog
                                  show={true}
                                  button={
                                    isUpdate
                                      ? i18n.t('button.update')
                                      : i18n.t('button.register')
                                  }
                                  head={textBox[0]}
                                  body={
                                    textBox[1] || (
                                      <FallBackLoader
                                        isLoading={status.isLoading}
                                      />
                                    )
                                  }
                                  goBack={i18n.t('employee.title')}
                                  urlGoBack='/employees'
                                  isValid={isValid}
                                  stay={
                                    isUpdate
                                      ? i18n.t('others.employee')
                                      : i18n.t('employee.titleCreate')
                                  }
                                  urlStay={
                                    isUpdate
                                      ? `/employees/${id}/edit`
                                      : '/employees/create'
                                  }
                                  disabled={
                                    isDisabled ||
                                    Object.keys(errors).length !== 0
                                  }
                                  type='submit'
                                />
                              ) : (
                                <GenericButton
                                  handler={() =>
                                    handleNextStep(
                                      values,
                                      errors,
                                      touched,
                                      setErrors
                                    )
                                  }
                                  variant='contained'
                                  label={i18n.t('button.next')}
                                />
                              )}
                            </Box>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
}
