import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const TextfieldWrapper = ({
  name,
  showDollar = false,
  multiline,
  styles,
  error,
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...otherProps,
    size: 'small',
    variant: 'outlined',
    fullWidth: true,
    label: false,
    multiline: multiline,
    style: { backgroundColor: 'white', ...styles },
    InputProps: showDollar
      ? {
          endAdornment: (
            <InputAdornment position='end'>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#42474B',
                  margin: '0px',
                  backgroundColor: 'white',
                }}
              >
                kz
              </p>
            </InputAdornment>
          ),
        }
      : {},
  };

  if ((meta?.touched && meta?.error) || error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return (
    <>
      <p
        style={{
          fontSize: '13px',
          fontWeight: '600',
          color: '#42474B',
          margin: '0px',
        }}
      >
        {otherProps.label}
      </p>
      <TextField {...configTextField} />
    </>
  );
};
TextfieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  showDollar: PropTypes.bool,
  multiline: PropTypes.bool,
  styles: PropTypes.object,
  label: PropTypes.string.isRequired,
  error: PropTypes.any,
};
export default TextfieldWrapper;
