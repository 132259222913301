import { Container, Grid } from "@mui/material";
import * as React from "react";
import enrollImage from "../../assets/enrollImage.png"
import information from "../../assets/information.png"
import call from "../../assets/call.png"
import mail from "../../assets/mail.png"
import logo from "../../assets/mainLogo.png"
import "./Enroll.css";
import FormContainer from "./FormContainer";
export default function EnrollStudentCreatePage() {

  return (
      <Grid container >
        <Grid item xs={9} sm={9}>
          <Container maxWidth="lg">
            <div className="enrollRightSide " style={{ marginLeft: "30px" }}>

              <img src={logo} />
              <FormContainer />
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="enrollLeftSide hidden-xs" >
            <img src={enrollImage} />
            <div className="enrollLeftSideContent">
              <div className="information">
                <img src={information} />
                <h2>Para Informações</h2>
              </div>
              <div className="information-contend">
                <p>Encontre aqui as informações de apoio para o processo de matrícula na FMUAN.</p>

                <div className="contact-group">
                  <img src={call} />
                  <p>954 552 708 - 952 275 073 - 937 601 616 </p>
                </div>
                <div className="contact-group">
                  <img src={mail} />
                  <p>daacmedicina2223@gmail.com
                    secretariafmuan@gmail.com</p>
                </div>
              </div>
            </div>

          </div>
        </Grid>

      </Grid>
    
  );
}
