import { Grid, Typography, Button } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import keycloak from "../../keycloak";
import GroupListingPreview from "./component/GroupListingPreview";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { MessageDialog } from "../../components/my-modal/MessageDialog";
import { useMessageModal } from "../../utils/hooks/useModals";
import {
  getErrorMessage,
  handleFailure,
} from "../../utils/functions/utilFunctions";

export default function ClassesRegisterPage() {
  let isDisabled= HandleUrl();

  const [incommingData, setincommingData] = useState({});

  const {
    open: openMessage,
    message,
    loading,
    setLoading,
    handleClose,
    handleClickOpen,
    changeState,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useMessageModal();

  const [className, setClassName] = useState("");
  const [createdGroups, setCreatedGroups] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();

  const INITIAL_FORM_STATE = {
    course: incommingData?.course || "",
    graduation: incommingData?.graduation || "",
    year: incommingData?.year || "",
    semester: incommingData?.semester || "",
    subject: incommingData?.subject?.name || "",
    numberOfStudents: incommingData?.numberOfStudents || 0,
    numberOfGroups: incommingData?.numberOfGroups || 0,
  };

  const [status, setStatus] = useState({
    isLoading: false,
    hasGroups: false,
  });
  const [textBox, setTextBox] = useState([i18n.t("messages.createTitle"), ""]);

  useEffect(() => {
    setTextBox([
      i18n.t(
        status.hasGroups ? "messages.updateTitle" : "messages.createTitle"
      ),
      "",
    ]);
  }, [status.hasGroups]);

  console.log("Initial form state: ", INITIAL_FORM_STATE);

  const FORM_VALIDATION = Yup.object().shape({
    course: Yup.object().shape({
      name: Yup.string().required("Course name is required"),
      graduation: Yup.string().required("Graduation is required"),
    }),
    year: Yup.number().required("Year is required"),
    semester: Yup.string().required("Semester is required"),
    subject: Yup.string().required("Subject is required"),
    numberOfStudents: Yup.number().required("Number of students is required"),
    numberOfGroups: Yup.number().required("Number of groups is required"),
  });

  console.log("Initial values: ", INITIAL_FORM_STATE);
  console.log("className: ", className);

  const getGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/group/${id}/class`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getGroups()
        .then((response) => {
          console.log("Group Data response: ", response);
          const groups =
            response?.data && response?.data?.data?.length
              ? response?.data?.data.sort((a, b) => a.id - b.id)
              : [];
          setCreatedGroups((prev) => ({
            ...prev,
            groups,
          }));

          setStatus((prev) => ({
            ...prev,
            hasGroups: groups.length > 0,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const getClasse = async () => {
    try {
      const response = await axios.get(`${apiUrl}/classes/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getClasse()
        .then((response) => {
          console.log("Class Data response: ", response?.data);

          setincommingData(response?.data);
          setClassName(response?.data?._name);
          setCreatedGroups((prev) => ({
            ...prev,
            numberOfStudents: response?.data?.numberOfStudents,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleClassNameChange = (ev) => {
    setClassName(ev.target.value);
  };
  const handleGroupNameChange = (groupId, newName) => {
    setCreatedGroups((prevGroups) => {
      // Find the group with the matching id
      const groupIndex = prevGroups.groups.findIndex(
        (group) => group.id === groupId
      );

      // If the group exists, update its name
      if (groupIndex !== -1) {
        const newGroupNames = { ...prevGroups };
        newGroupNames.groups[groupIndex].name = newName;
        return newGroupNames;
      }

      // If the group doesn't exist, return the previous state
      return prevGroups;
    });
  };



  function createGroups(values) {
    setLoading(true);
    handleClickOpen();

    try {
      const formData = {
        classeId: parseInt(id),
        numOfGroups: parseInt(values.numberOfGroups),
      };

      axios
        .post("group", formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            const groups =
              response?.data && response?.data.length ? response?.data : [];
            setCreatedGroups({
              groups,
              numberOfStudents: values.numberOfStudents,
            });
            setStatus((prev) => ({ ...prev, hasGroups: true }));
            handleSuccess();
          } else {
            handleBadRequest(response);
          }
        })
        .catch(function (error) {
          console.error(error);
          errorFallback(error);
        });
    } catch (error) {
     
      errorFallback(error);
    }
  }

  function updateGroups(values) {
    const errorFallback = (error) => {
      console.error(error);
      setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    };

    const handleFail = (response) => {
      console.error("Fail: ", response);
      setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    };

    setStatus((prev) => ({ ...prev, isLoading: true }));

    try {
      const groupsDto = createdGroups.groups.map((group) => ({
        groupId: group.id,
        name: group.name,
        studentsId: group.students.map((student) => parseInt(student.id)),
      }));

      const formData = {
        classeId: parseInt(id),
        numberOfGroups: parseInt(values.numberOfGroups),
        groupsDto,
      };

      console.log("values:", values);
      // Make a PUT request to update the group
      axios
        .put(`/group`, formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            console.log(response, formData, "Atualizar grupos");
            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t(
                status.hasGroups
                  ? "messages.updateMessage"
                  : "messages.createMessage"
              );
              return textCopy;
            });
            if (className !== incommingData._name) {
              updateClass(errorFallback, handleFail);
            }
            setStatus((prev) => ({ ...prev, isLoading: false }));
          } else {
            handleFail(response);
          }
        })
        .catch(function (error) {
          errorFallback(error);
        });
    } catch (error) {
      errorFallback(error);
    }
  }

  function updateClass(handleError, handleFail) {
    try {
      const formData = {
        name: className,
      };

      axios
        .patch(`/classes/name/${id}`, formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t("messages.updateMessage");
              return textCopy;
            });
            setStatus((prev) => ({ ...prev, isLoading: false }));
            console.log("Atualizar Classe: ", response);
            return;
          } else {
            handleFail(response);
          }
        })
        .catch(function (error) {
          handleError(error);
        });
    } catch (error) {
      handleError(error);
    }
  }

  console.log("hasGroups: ", status.hasGroups);

  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: "white",
        }}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, { validateForm }) => {
            validateForm(values).then((errors) => {
              if (Object.keys(errors).length === 0) {
                // No validation errors
                setStatus((prev) => ({ ...prev, isLoading: true }));
                if (status.hasGroups) {
                  updateGroups(values);
                } else {
                  createGroups(values);
                  console.log("Creating values:", values);
                }
              } else {
                // Log validation errors
                console.log("Form validation errors:", errors);
              }
            });
          }}
          enableReinitialize
        >
          <Form style={{ padding: "50px 100px" }}>
            <Typography variant="h1" sx={{ marginBottom: "30px" }}>
              {i18n.t("others.createGroups")}
            </Typography>
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Grid sx={{ display: "flex" }}>
                <Grid item sx={{ width: "350px" }}>
                  <Textfield
                    name="course.name"
                    label={i18n.t("others.course")}
                  />
                </Grid>

                <Grid item sx={{ width: "350px", marginLeft: "40px" }}>
                  <Textfield
                    name="course.graduation"
                    placeholder={i18n.t("others.graduation")}
                    label={i18n.t("others.graduation")}
                  />
                </Grid>
              </Grid>

              <Grid sx={{ display: "flex", marginTop: "30px" }}>
                <Grid item sx={{ width: "160px" }}>
                  <Textfield
                    name="year"
                    label={i18n.t("others.year")}
                  />
                </Grid>

                <Grid item sx={{ width: "160px", marginLeft: "30px" }}>
                  <Textfield
                    name="semester"
                    label={i18n.t("others.semester")}
                  />
                </Grid>

                <Grid item sx={{ width: "350px", marginLeft: "40px" }}>
                  <Textfield
                    name="subject"
                    label={i18n.t("others.subject")}
                  />
                </Grid>
              </Grid>

              <Grid sx={{ display: "flex", marginTop: "30px" }}>
                <Grid item sx={{ width: "160px" }}>
                  <Textfield
                    type="number"
                    name="numberOfStudents"
                    
                    label={i18n.t("others.nºStudents")}
                  />
                </Grid>

                <Grid item sx={{ width: "160px", marginLeft: "30px" }}>
                  <Textfield
                    type="number"
                    name="numberOfGroups"
                    disabled={isDisabled}
                    placeholder={i18n.t("others.nºGroups")}
                    label={i18n.t("others.nºGroups")}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    marginLeft: "40px",
                    marginTop: "15px",
                  }}
                >
                  <Button
                    variant="text"
                    type="submit"
                    disabled={isDisabled}
                    sx={{
                      width: "150px",
                      height: "35.5px",
                    }}
                  >
                    {i18n.t("others.generateGroups")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {status.hasGroups && (
              <>
                <Grid>
                  <GroupListingPreview
                    turma={createdGroups}
                    handleClassNameChange={handleClassNameChange}
                    className={className}
                    handleGroupNameChange={handleGroupNameChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "480px",
                      paddingBottom: "80px",
                      paddingTop: "50px",
                      gap: "10px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <MainButton
                        url="/classes"
                        name={i18n.t("button.cancel")}
                      />
                    </span>

                    <span style={{ marginRight: "30px" }}>
                      <AlertDialog
                        button={
                          status.hasGroups
                            ? i18n.t("button.update")
                            : i18n.t("button.register")
                        }
                        goBack={i18n.t("class.title")}
                        head={textBox[0]}
                        body={
                          textBox[1] || (
                            <FallBackLoader isLoading={status.isLoading} />
                          )
                        }
                        urlGoBack="/classes"
                        stay={
                          status.hasGroups
                            ? i18n.t("others.class")
                            : i18n.t("others.title")
                        }
                        urlStay={
                          status.hasGroups
                            ? `/classes/${id}/edit?disabled=true`
                            : `/classes`
                        }
                        type="submit"
                        isValid={true}
                        isLoading={status.isLoading}
                        disabled={isDisabled}
                      />
                    </span>
                  </div>
                </Grid>
              </>
            )}
          </Form>
        </Formik>
      </Grid>

      {changeState && (
        <MessageDialog
          id={id}
          open={openMessage}
          handleClose={handleClose}
          message={message}
          isLoading={loading}
        />
      )}
    </>
  );
}
