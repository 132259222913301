import {
   Grid,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Typography,
   TableCell as StyledTableCell,
} from "@mui/material";
import * as React from "react";
import { i18n } from "../../../translate/i18n";
import { useState, useEffect } from "react";
import axios from "axios";
import keycloak from "../../../keycloak";
import CustomPagination from "../../../components/pagination";
import FilterComponet from "../components/filter/filterComponet";
import Loading from "../../../components/loading/loading";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";

export default function CourseStudents({
   searchTerm,
   selectedYear,
   selectedCourse,
   selectedSemester,
   selectedSubject,
}) {
   const apiUrl = process.env.REACT_APP_API_URL;
   const [incomingDate, setIncomingDate] = useState([]);
   const [page, setPage] = useState(localStorage.getItem("courseTeachersPage") || 1);
   const [totalPages, setTotalPages] = useState();

   const [loading, setLoading] = useState(false);

   const { id } = useParams();

   const pageSeter = (page) => {
      setPage(page);
      localStorage.setItem("courseTeachersPage", page);
   };
   const filteringProps = {
      year: selectedYear,
      name: selectedCourse,
      semester: selectedSemester,
      subject: selectedSubject,
      // search: searchTerm,
   };

   const getTeachersByCourseId = async () => {
      setLoading(true);

      try {
         const teachersResponse = await axios.get(
            `${apiUrl}/teacher-semester-subject-plan/list-teacher/courses/${id}?`,
            {
               headers: { Authorization: "Bearer " + keycloak.token },
               params: {
                  search: searchTerm,
                  ...filteringProps, // Passando os filtros como parâmetros
               },
            }
         );

         const teachersData = teachersResponse?.data || teachersResponse?.data?.data || {};

         const filteredTeacherData = teachersData.filter(
            (data) =>
               data.year === filteringProps.year &&
               data.name === filteringProps.name &&
               data.semester === filteringProps.semester &&
               data.subject === filteringProps.subject
         );

         setIncomingDate(teachersData);
         console.log("incomingDate", incomingDate);

         setTotalPages(teachersData.meta?.totalPages);
         if (localStorage.getItem("courseTeachersPage")) pageSeter(teachersData.data.page);
      } catch (error) {
         console.error("Erro ao obter dados dos professores:", error);
      } finally {
         setLoading(false);
      }
   };

   console.log("searchTerm", searchTerm);
   useEffect(() => {
      if (keycloak.token) {
         getTeachersByCourseId();
      }
   }, [keycloak.token, page, searchTerm]);

   return (
      <Grid
         style={{
            backgroundColor: "white",
         }}
      >
         <Grid item xs={12}>
            {" "}
            {loading && <Loading />}
            <TableCell
               style={{
                  height: "35px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #E9ECF5",
                  marginTop: "30px",
               }}
            >
               <Grid>
                  <Typography variant="h3">{i18n.t("teacher.title")}</Typography>
               </Grid>

               <Grid>
                  <FilterComponet />
               </Grid>
            </TableCell>
            <Table sx={{ minWidth: 650, border: "1px solid #E9ECF5" }} size="small" aria-label="a dense table">
               <TableHead>
                  <TableRow height="56px" sx={{ backgroundColor: "#F8FAFD" }}>
                     <StyledTableCell align="left">{i18n.t("teacher.cardId")}</StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("teacher.name")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("teacher.telephone")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("teacher.email")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("teacher.theoreticalDisciplines")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("teacher.practicalDisciplines")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("teacher.availability")} </StyledTableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {incomingDate
                     ?.filter((data) => {
                        const searchTeacherName = searchTerm?.toLowerCase();

                        return searchTeacherName === "" || data.teacher.name.toLowerCase().includes(searchTeacherName);
                     })
                     .map((item) => (
                        <TableRow key={item.teacher.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                           <TableCell component="th" scope="row" align="center">
                              {item.teacher?.idCard}
                           </TableCell>
                           <TableCell component="th" scope="row" align="center">
                              {item.teacher?.name}
                           </TableCell>
                           <TableCell component="th" scope="row" align="center">
                              {item.teacher?.phone}
                           </TableCell>
                           <TableCell component="th" scope="row" align="center">
                              {item.teacher?.email}
                           </TableCell>
                           <TableCell component="th" scope="row" align="center">
                              {item.subjects?.theoretical?.map((subject, id) => (
                                 <div key={subject.id}>{subject.name}</div>
                              ))}
                           </TableCell>
                           <TableCell component="th" scope="row" align="center">
                              {item.subjects?.practical?.map((subject, id) => (
                                 <div key={subject.id}>{subject.name}</div>
                              ))}
                           </TableCell>

                           <TableCell component="th" scope="row" align="center">
                              {item.teacher.availability?.map((availability, id) => (
                                 <div key={availability.id}>{availability.name}</div>
                              ))}
                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
            {totalPages > 1 && (
               <Grid style={{ display: "flex", justifyContent: "center" }}>
                  <CustomPagination totalPages={totalPages} setPage={setPage} page={page} />
               </Grid>
            )}
         </Grid>
      </Grid>
   );
}
