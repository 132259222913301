//Note: To use filter and match functions you might need to adjust for incomingData

// Extracts unique values from incoming data based on a specified property path
export const extractUniqueValues = (data, propertyName, valueModifier) => {
  return Array.isArray(data)
    ? Array.from(
        new Set(
          data
            .map((item) => {
              const value = item[propertyName];
              if (typeof valueModifier === "function") {
                return valueModifier(value) || value;
              }
              return value;
            })
            .filter(Boolean)
        )
      )
    : [];
};

export const flattenArray = (array, rootPropertyName) => {
  return array?.map((item) => {
    const rootProperty = item?.[rootPropertyName];
    return {
      ...item,
      ...rootProperty,
    };
  });
};

// Example usage for names, graduations, departments, totalYears, and prices
// const names = extractUniqueValues(IncomingData?.data, 'name');
// const graduations = extractUniqueValues(IncomingData?.data, 'graduation');
// ... and so on for departments, totalYears, and prices

// Filters items based on the selected filter properties
export const filterFunction = (item, filterProps) => {
  return filterProps.every((prop) => {
    const propName = Object.keys(prop)[0];
    const propValues = prop[propName];
    if (propValues.length === 0) return true;
    if (Array.isArray(item[propName])) {
      return item[propName].some((value) => propValues.includes(value.name));
    }
    if (item[propName] !== undefined) {
      return propValues.some((value) =>
        item[propName]
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      );
    }
    return false;
  });
};

// Matches items based on a search term and specified properties
export const matchFunction = (item, compareValue, properties) => {
  if (item) {
    return properties.some((prop) => {
      const lowercaseItem = item[prop]?.toString()?.toLowerCase() || "";
      const lowercaseValue = compareValue?.toLowerCase() || "";
      return lowercaseItem.includes(lowercaseValue);
    });
  }
  return false;
};

/**
 * Determines the items to be used based on search/filter conditions.
 * @param {Array} incomingData - The original data array.
 * @param {Array} filteredData - The data array after applying filters.
 * @param {string} searchTerm - The current search term.
 * @param {boolean} canFilter - A flag indicating if filtering is applied.
 * @returns {Array} The appropriate data array based on the conditions.
 */
export function determineArray(
  incomingData,
  filteredData,
  searchTerm,
  canFilter
) {
  let items = searchTerm || canFilter ? filteredData : incomingData;
 
  return Array.isArray(items) ? items : [];
}
