import { Container, Grid, Typography } from "@mui/material";
import question from '../../../assets/question.png'
import { i18n } from "../../../translate/i18n";

const IssueDocumentConfirmation = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return (
    <Grid container style={{ backgroundColor: "white" }}>
      <Grid item xs={8} style={{ padding: "120px" }}>
        <Container maxWidth='lg'>
          <div className='enroll-confirmation'>
            <h2>{i18n.t('studentFee.documentRequest')}</h2>
            <div className='confirmation-body'>
              <div className='flex'>
                <div>
                  <h3>{i18n.t('others.name')}:</h3>
                </div>
                <div>
                  <p>{searchParams.get("name")}</p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <h3>{i18n.t('studentFee.identification')}:</h3>
                </div>
                <div>
                  <p>{searchParams.get("mechanographicNumber")}</p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <h3>{i18n.t('studentFee.documentType')}:</h3>
                </div>
                <div>
                  <p>{searchParams.get("docType")}</p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <h3>{i18n.t('others.paymentMethod')}:</h3>
                </div>
                <div>
                  <p>{i18n.t('studentFee.paymentReference')}</p>
                </div>
              </div>
            </div>
            <div className='confirmation-footer' style={{ display: "flex" }}>
              <div>
                <img src={question} alt="Question" />
              </div>
              <div>
                <Typography variant='p'>{i18n.t('studentFee.documentProcessing')}</Typography>
                <Typography sx={{ textAlign: 'justify', color: "#949494" }} variant='p'>
                  {i18n.t('studentFee.paymentReferenceEmail')}
                </ Typography>
              </div>
            </div>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}

export default IssueDocumentConfirmation;
